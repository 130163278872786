<template>
<div id="page" class="w-full flex flex-col items-center">
  <div class="w-full h-auto mb-60 font-rouben text-new-white px-8 md:px-20 md:max-w-screen-2xl">
    <div class="mt-60 leading-tight tracking-wide w-full md:w-10/12 font-extrabold md:font-bold text-4xl md:text-5xl text-center md:text-left break-words hyphen">{{text.news}}</div>
    <div class="flex flex-col md:flex-row flex-wrap mt-8">
      <div v-for="(news, index) in allNews?.news?.data" :key=news class="w-full md:w-1/2 h-auto" :class="{'mt-50 md:pl-12' : index % 2 !== 0, 'md:pr-12' : index % 2 == 0,}">
        <div class="leading-tight tracking-wide w-full md:w-10/12 font-semibold md:font-bold text-2xl md:text-5xl text-left mt-5 md:mt-5 break-words hyphen cursor-pointer" @click="openNews(index)">{{news?.attributes?.Titel}}</div>
        <div>{{news?.attributes?.Datum}}</div>
        <div class=" w-full h-auto overflow-hidden mt-2" @click="openNews(index)">
          <img :src="news?.attributes?.Media?.data?.attributes?.url" class="transition transform-gpu duration-1000 ease-in-out w-full aspect-auto filter hover:scale-105 brightness-75 hover:brightness-100">
        </div>
        <div v-html="convertMarkdown(news?.attributes?.Text)" class="md:w-full markdown whitespace-pre-line text-base mb-3 leading-7 mt-3" />
      </div>
    </div>
  </div>
  <div class="w-full md:flex md:flex-col md:items-center">
    <footerComponent />
  </div>
  <queryAllNews />
</div>
</template>

<script>
import showHide from '../mixins/showHide.vue';
import convertMarkdown from '../mixins/convertMarkdown.vue';

// GraphQL
import queryAllNews from '../graphql/queryAllNews.vue';

// Components
import footerComponent from '../components/footer.vue';

export default {
  name: 'News',
  components: { footerComponent, queryAllNews },
  mixins: [showHide, convertMarkdown],

  data() {
    return {
      currentNewsIndex: '',
    };
  },

  mounted() {
    window.scrollTo({ top: 0, left: 0 });
  },

  computed: {
    allNews() {
      return this.$store.state.data.allNews;
    },
    text() {
      return this.$store.state.lang.news[this.$store.state.lang.currentLang];
    },
  },

  watch: {
    allNews() {
      document.title = this.text.news;
    },
  },

  methods: {
    openNews(index) {
      if (this.currentNewsIndex === index) {
        this.currentNewsIndex = '';
      } else {
        this.currentNewsIndex = index;
      }
    },

    scrollTo(el) {
      if (el) {
        window.scrollTo({ top: el.offsetTop - 150, left: 0, behavior: 'smooth' });
      }
    },
  },

};
</script>
