<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryAllNews',
  watch: {
    allNews() {
      this.$store.commit('setAllNews', this.allNews);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
    });

    const queryAllNews = gql`
      query ($lang:I18NLocaleCode!){
        news (locale: $lang, sort: "Datum:desc") {
          data {
            attributes {
              Titel
              Text
              Datum
              Media {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `;
    const { data: allNews } = useQuery({
      query: queryAllNews,
      variables,
      context,
    });
    return { allNews };
  },
};
</script>
